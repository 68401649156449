import SmoothScroll from 'smooth-scroll';
import debounce from 'debounce';

/**
 * Create an element from HTML.
 *
 * @see https://stackoverflow.com/a/494348/1136822
 * @param {string} htmlString
 * @return {node|null}
 */
const createElementFromHTML = function(htmlString) {
	let div = document.createElement('div');
	div.innerHTML = htmlString.trim();

	// Change this to div.childNodes to support multiple top-level nodes
	return div.firstChild;
};

/**!
 * Down link
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
export default class DownLink {
	_el;
	_baseClass;
	_inClass;
	_outClass;
	_speed;
	_offset;
	_tolerance;
	_alreadyTriggered = false;

	/**
	 * Creates the dropdown link element and initializes it.
	 *
	 * @param {string} htmlString
	 * @param {object} options
	 * @param {string} parentSelector
	 */
	constructor(htmlString, options = {}, parentSelector = 'body') {
		let el = createElementFromHTML(htmlString);
		let parent = document.querySelector(parentSelector);
		parent.appendChild(el);
		this._init(el, options);
	}

	/**
	 * Initializes the dropdown link from an element.
	 *
	 * @param {node} el
	 * @param {string} baseClass
	 * @param {string} inClass
	 * @param {string} outClass
	 * @param {int} speed
	 * @param {int} offset
	 * @param tolerance
	 */
	_init(el, {
		baseClass = 'animated',
		inClass = 'fadeIn',
		outClass = 'fadeOut',
		speed = 500,
		offset = 0,
		tolerance = 250
	} = {}) {

		this._el = el;
		this._baseClass = baseClass;
		this._inClass = inClass;
		this._outClass = outClass;
		this._speed = speed;
		this._offset = offset;
		this._tolerance = tolerance;

		//add the base class
		if (!!this._baseClass) {
			this._el.classList.add(this._baseClass);
		}
		//debounced version, because of performance and stuff
		const debouncedUpdate = debounce(() => {
			this._update();
		}, 10, false);

		//listen for scroll
		window.addEventListener('scroll', debouncedUpdate);

		//set up smooth scroll
		let scroller = new SmoothScroll();

		//listen for click
		this._el.addEventListener('click', e => {
			e.preventDefault();
			let topPos = this._el.getBoundingClientRect().top + window.scrollY;
			scroller.animateScroll(topPos, null, {
				speed: speed,
				offset: offset
			});
		});

		//call immediately
		this._update();
	}

	/**
	 * Updates the classes on the element.
	 */
	_update() {
		let scrollTop  = window.pageYOffset || document.documentElement.scrollTop;
		let pageHeight = document.documentElement.scrollHeight;
		let windowHeight = window.innerHeight|| document.documentElement.clientHeight || document.body.clientHeight;

		if ( scrollTop > 0 || pageHeight - this._tolerance <= windowHeight ) {
			this._el.classList.remove(this._inClass);
			this._el.classList.add(this._outClass);
			this._alreadyTriggered = true;
			this._el.inert = true;
			//window.removeEventListener('scroll', debouncedUpdate);
		} else {
			if (!this._alreadyTriggered) {
				this._el.classList.remove(this._outClass);
				this._el.classList.add(this._inClass);
			}
		}
	};

	reset() {
		this._alreadyTriggered = false;
		this._el.inert = false;
		this._update();
	}
}
