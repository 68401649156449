import dynamicStyles from '../dynamicStyles';

let apps = [];
let appHolderDivs = [];

export default {
	init(selector = '[data-availability-toggle]', parentSelector = '.main') {
		let parentEl = document.querySelector(parentSelector);
		if (parentEl) {
			let els = document.querySelectorAll(selector);
			if (els.length) {
				//load the styles
				dynamicStyles.addStylesheet('/dist/styles/availabilityApp.min.css');

				//first let's load vue
				import(/*webpackChunkName: "vue"*/ 'vue').then(({default: Vue}) => {
					//now let's load the location map component
					import(/*webpackChunkName: "availabilityApp"*/ './Availability.vue').then(({default: AvailabilityApp}) => {

						//mount the app
						let Comp = Vue.extend(AvailabilityApp);
						els.forEach(el => {
							//get the info var
							let infoVar = el.getAttribute('data-availability-toggle');

							//create the new div
							let appHolderDiv = document.createElement('div');
							appHolderDiv.setAttribute('data-availability', infoVar);
							appHolderDiv.setAttribute('class', 'availability-holder');
							parentEl.appendChild(appHolderDiv);
							appHolderDivs.push(appHolderDiv);

							let appDiv = document.createElement('div');
							appHolderDiv.appendChild(appDiv);

							let app = new Comp({
								propsData: {
									infoVar: infoVar
								}
							}).$mount(appDiv);
							apps.push(app);
						});
					});
				});
			}
		}
	},
	destroy() {
		apps.forEach(app => {
			app.$destroy();
		});
		apps = [];
		appHolderDivs.forEach(appHolderDiv => {
			appHolderDiv.parentNode.removeChild(appHolderDiv);
		});
		appHolderDivs = [];
	}
}
