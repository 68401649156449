import ListenerManager from './listenerManager';
let listenerBoss = new ListenerManager();

export default {
	init(selector = '[data-virtual-tour]') {
		let els = document.querySelectorAll(selector);
		if (els.length) {
			head.load({tour: '/assets/js/tour.js'}, () => {
				els.forEach(el => {
					let btn = el.querySelector('button');
					let basePath = el.getAttribute('data-virtual-tour');

					const loadTour = function() {
						//remove the click event
						listenerBoss.remove(btn,'click', loadTour);
						listenerBoss.remove(btn,'touchstart', loadTour);

						//empty the div
						el.innerHTML = '';

						//turn off web vr on iPhone
						let vars = {};
						if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
							vars['webvr'] = false;
						}

						//embed the pano
						embedpano({
							swf: basePath+'tour.swf',
							xml:  basePath+'tour.xml',
							target: el.getAttribute('id'),
							html5: 'auto',
							mobilescale: 1.0,
							mwheel: false,
							vars
						});
					};

					//add the click event
					listenerBoss.register(btn,'click', loadTour);
					listenerBoss.register(btn,'touchstart', loadTour);
				});
			});
		}
	},
	destroy() {
		listenerBoss.removeAll();
	}
}
