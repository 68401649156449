import dynamicStyles from '../dynamicStyles';

let apps = [];

export default {
	init(selector = '[data-location-map]') {
		let els = document.querySelectorAll(selector);
		if (els.length) {
			//load the styles
			dynamicStyles.addStylesheet('/dist/styles/locationMap.min.css');

			//first let's load vue
			import(/*webpackChunkName: "vue"*/ 'vue').then(({default:Vue}) => {
				//now let's load the location map component
				import(/*webpackChunkName: "locationMap"*/ './LocationMap.vue').then(({default:LocationMap}) => {
					//mount the app
					let Comp = Vue.extend(LocationMap);
					els.forEach(el => {
						let app = new Comp({
							propsData: {
								apiKey: el.getAttribute('data-api-key') || '',
								infoVar: el.getAttribute('data-info-var') || '',
								unique: el.getAttribute('data-unique') || ''
							}
						}).$mount(el);
						apps.push(app);
					});
				});
			});
		}
	},
	destroy() {
		apps.forEach(app => {
			app.$destroy();
		});
		apps = [];
	}
}
