// ---------- Config Setup ----------
const SITE = window.SITE || {};
//const isDevMode = SITE.devMode || false;
//const isProduction = SITE.isProduction || false;
global.isAdminLoggedIn = SITE.isAdminLoggedIn || false;
global.mobileMenuMaxWidth = SITE.mobileMenuMaxWidth || 0;

// ---------- Variables ----------
const scrollSpeed = 500;
const scrollOffset = 20;
const scrollHeader = '[data-scroll-header]';

// ---------- Polyfills ----------
import 'svgxuse';
import 'focus-visible/dist/focus-visible';
import 'wicg-inert/dist/inert';

// ---------- js class ----------
import './Components/jsClass';

// ---------- ie11 class ----------
import './Components/ie11Class';

// ---------- lazysizes ----------
import lazySizes from 'lazysizes';
//import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
Object.assign(lazySizes.cfg, {loadMode:1});

// ---------- smooth scroll ----------
import SmoothScrollLinks from './Components/smoothScroll';
SmoothScrollLinks.init(scrollSpeed, scrollOffset, scrollHeader);

// ---------- scroll indicator ----------
import DownLink from './Components/downLink';
let downLink = new DownLink('<div class="scrollLink-wrapper"><button class="scrollLink" aria-label="Scroll down"><svg class="icon icon-arrow-down" aria-hidden="true"><use href="#arrow-down"></use></svg></button></div>', {speed:scrollSpeed}, '.footer');

// ---------- back to top button ----------
import topLink from './Components/topLink';
topLink.initFromHtml('<div class="topLink-wrapper"><button class="topLink"><svg class="icon icon-arrow-up" aria-label="Scroll to top"><use href="#arrow-up" aria-label="hidden"></use></svg></button></div>', {speed:scrollSpeed, topDistance:400}, '.footer');

// ---------- floating header ----------
import floating from './Components/floatingElement';
floating.init();

// ---------- sliders ----------
import flickitySliders from './Components/flickitySliders';

// ---------- mobile menu ----------
import MobileMenu from './Components/mobileMenu';
const mobileNav = new MobileMenu({
	openClass: 'mobileNav-isOpen',
	closeClass: 'mobileNav-isClosed',
	toggleSelector: '[data-nav-toggle]',
	maxWidth: 1024,
	onOpen() {
		//make the main and footer elements inert
		document.querySelectorAll('.main, .footer').forEach(item => {
			item.inert = true;
		});

		this.mobileMenuToggles.forEach(mobileMenuToggle => {
			//set the aria expanded attributes
			mobileMenuToggle.setAttribute('aria-expanded', true);

			//set the aria label attribute
			mobileMenuToggle.setAttribute('aria-label', 'Close menu');
		});
	},
	onClose() {
		//make the main and footer elements not inert
		document.querySelectorAll('.main, .footer').forEach(item => {
			item.inert = false;
		});
		this.mobileMenuToggles.forEach(mobileMenuToggle => {
			//set the aria expanded attributes
			mobileMenuToggle.setAttribute('aria-expanded', false);

			//set the aria label attribute
			mobileMenuToggle.setAttribute('aria-label', 'Open Menu');
		});
	}
});
mobileNav.close();

import MenuToggles from './Components/menuToggles';
const menuToggles = new MenuToggles({
	onOpen(toggle) {
		if (!toggle.classList.contains('selected')) {
			toggle.classList.add(this._openClass);
			toggle.setAttribute('aria-expanded', 'true');
		}
	},
	onClose(toggle) {
		if (!toggle.classList.contains('selected')) {
			toggle.classList.remove(this._openClass);
			toggle.setAttribute('aria-expanded', 'false');
		}
	}
});
menuToggles.init();

// ---------- svg sprite ----------
import './Components/svgSprite';

// ---------- map ----------
import simpleMap from './Components/simpleMaps';

// ---------- aos ----------
//import AOS from 'aos';

// ---------- responsive videos ----------
import resposiveVideos from './Components/responsiveVideos';

// ---------- lightbox ----------
import MediaLightbox from './Components/lightbox';
let holder = document.querySelector('.holder');
let lightbox = new MediaLightbox({
	onOpen() {
		if (holder) {
			holder.inert = true;
		}
	},
	onClose() {
		if (holder) {
			holder.inert = false;
		}
	}
});
window.lightbox = lightbox;

// ---------- vue apps ----------
import locationMap from './Components/locationMap/locationMap';
import availabilityApp from './Components/availabilityApp/availabilityApp';

// ---------- scrollbar compensation ----------
import scrollbarCompensation from './Components/scrollbarCompensation';
scrollbarCompensation.init();

// ---------- Calendar Links ----------
import calendarLinks from './Components/icsLink';

// ---------- top bar ----------
import topbar from 'topbar';
topbar.config({
	barColors: {0:'rgba(220, 107, 47, 1)', 1:'rgba(220, 107, 47, 1)'},
	shadowBlur: 0,
	shadowColor  : 'rgba(0, 0, 0, 0)'
});

// ---------- Virtual Tours ----------
import virtualTours from './Components/virtualTours';

// ---------- Parallax ----------
import parallax from './Components/parallax';

// ---------- popover ----------
import Popover from './Components/popover';
let popover = new Popover({
	onOpen() {
		if (holder) {
			holder.inert = true;
		}

		//focus the alert text
		let textEl = document.querySelector('.popover-text h2') || document.querySelector('.popover-text');
		if (textEl) {
			textEl.setAttribute('tabindex', '-1');
			textEl.focus();
		}
	},
	onClose() {
		if (holder) {
			holder.inert = false;
		}
	}
});
popover.init();

// ---------- video cover (for background video) ----------
import videoCover from './Components/videoCover';

// ---------- on page load ----------
let navLinks = document.querySelectorAll('.nav ul a, .nav ul button, .mobileNav ul a, .mobileNav ul button');
let nav = document.querySelector('.nav');
let navParents = document.querySelectorAll('.nav .hasChildren, .mobileNav .hasChildren');
const onPageLoad = function(isInitialLoad = false) {

	// ---------- nav ----------
	//handle the homepage nav styling
	let currentUrl = window.location.href.split("#")[0];
	let isHomepage = (window.location.pathname === '/');
	if (nav) {
		if (isHomepage) {
			nav.classList.add('nav--home');
		} else {
			nav.classList.remove('nav--home');
		}
	}

	//handle the selected states
	if (navLinks.length) {
		navLinks.forEach(navLink => {
			if (navLink.href === currentUrl) {
				navLink.classList.add('selected');
			} else if (currentUrl.indexOf(navLink.href) > -1) {
				navLink.classList.add('selected');
			} else {
				navLink.classList.remove('selected');
			}

			if (navLink.href === currentUrl) {
				navLink.setAttribute('aria-current', 'page');
			} else {
				navLink.removeAttribute('aria-current');
			}
		});
	}

	//make sure nav parents are set as active parents if they have an active child
	if (navParents.length) {
		navParents.forEach(navParent => {
			let hasSelectedChild = !!navParent.querySelector('.selected');
			let firstLink = navParent.querySelector(':scope > a, :scope > button');
			if (firstLink) {
				if (hasSelectedChild) {
					navParent.classList.add('activeParent');
					firstLink.classList.add('selected');
					firstLink.setAttribute('aria-expanded', 'true');
				} else {
					navParent.classList.remove('activeParent');
					firstLink.classList.remove('toggle--open');
					firstLink.setAttribute('aria-expanded', 'false');
				}
			}
		});
	}

	// ---------- csrf tokens ----------
	window.csrfTokenName = document.querySelector('meta[name="csrf-name"]').getAttribute('content');
	window.csrfTokenValue = document.querySelector('meta[name="csrf-value"]').getAttribute('content');

	// ---------- scroll indicator ----------
	downLink.reset();

	// ---------- sliders ----------
	if (!isInitialLoad) {
		flickitySliders.destroy();
	}
	flickitySliders.init();

	// ---------- video cover ----------
	if (!isInitialLoad) {
		videoCover.destroy();
	}
	videoCover.init();

	// ---------- map ----------
	if (!isInitialLoad) {
		simpleMap.destroy();
	}
	simpleMap.init();

	// ---------- aos ----------
	/*if (isInitialLoad) {
		AOS.init();
	} else {
		AOS.refresh();
	}*/

	// ---------- responsive videos ----------
	resposiveVideos.destroy();
	resposiveVideos.init();

	// ---------- location map ----------
	if (!isInitialLoad) {
		locationMap.destroy();
	}
	locationMap.init();

	// ---------- availability app ----------
	if (!isInitialLoad) {
		availabilityApp.destroy();
	}
	availabilityApp.init();

	// ---------- lightbox ----------
	lightbox.init();

	// ---------- Calendar Links ----------
	calendarLinks.init();

	// ---------- Parallax ----------
	if (!isInitialLoad) {
		parallax.destroy();
	}
	parallax.init();

	// ---------- Virtual Tours ----------
	if (!isInitialLoad) {
		virtualTours.destroy();
	}
	virtualTours.init();
};
onPageLoad(true);


document.addEventListener('pjax:success', () => {
	onPageLoad(false);
});
document.addEventListener('pjax:send', () => {
	// ---------- nav ----------
	mobileNav.close();

	// ---------- lightbox ----------
	lightbox.close();

	// ---------- topbar ----------
	topbar.show();
});

document.addEventListener('pjax:complete', topbar.hide);

// ---------- pjax ----------
import Pjax from 'pjax';
new Pjax({
	elements: 'a[href]:not(.no-pjax):not([href$=".gif"]):not([href$=".jpg"]):not([href$=".png"]):not([href$=".pdf"]):not([href$=".json"]), form[action]',
	selectors: [
		'head > title',
		'meta[name="description"]',
		'meta[name="csrf-name"]',
		'meta[name="csrf-value"]',
		'.main'
	],
	cacheBust: false,
	analytics: function() {
		window.dataLayer = window.dataLayer || [];

		dataLayer.push({
			event: 'Pageview',
			pagePath: location.href,
			pageTitle: document.title,
		});
	}
});

// ---------- focus within polyfill ----------
/*
let isFocusWithinSupported = () => {
	try {
		document.querySelector(':focus-within')
	} catch (error) {
		return false;
	}

	return true;
};
if (!isFocusWithinSupported()) {
	import(/!*webpackChunkName: "focus-within-polyfill"*!/ './Components/focus-within');
}
*/
