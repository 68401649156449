// ---------- Flickity Slider ----------

//see https://flickity.metafizzy.co

import Flickity from 'flickity';

let initiatedSliders = [];

export default {
	init({
		     sliderSelector = '[data-flickity-slider]',
		     attribute = 'data-flickity-slider',
		     slideSelector = '.slide'
	     } = {}) {

		let flickitySliders = document.querySelectorAll(sliderSelector);
		flickitySliders.forEach(flickitySlider => {
			let sliderType = flickitySlider.getAttribute(attribute);

			let slides = flickitySlider.querySelectorAll(slideSelector);

			//default settings
			let settings = {
				autoPlay: false,
				//cellAlign: 'left',
				contain: true,
				pageDots: false,
				prevNextButtons: slides.length > 1,
				wrapAround: true
			};

			if (sliderType === 'hero') {
				settings = {
					autoPlay: 5000,
					pauseAutoPlayOnHover: false,
					//cellAlign: 'left',
					contain: true,
					pageDots: false,
					prevNextButtons: false,
					wrapAround: true
				};
			} else if (sliderType === 'textImageSplit') {
				settings = {
					autoPlay: 5000,
					pauseAutoPlayOnHover: false,
					//cellAlign: 'left',
					contain: true,
					pageDots: false,
					prevNextButtons: false,
					wrapAround: true
				};
			}

			//set up the main slider
			let slider = new Flickity(flickitySlider, settings);
			initiatedSliders.push(slider);
		});
	},
	destroy() {
		initiatedSliders.forEach(initiatedSlider => {
			try {
				initiatedSlider.destroy();
			} catch (e) {
				console.log(e);
			}
		});
		initiatedSliders = [];
	}
}
