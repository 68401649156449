let head = document.querySelector('head');

export default {
	addStylesheet: function(link) {
		let alreadyExists = document.querySelector('link[href="'+link+'"]');
		if (!alreadyExists) {
			let tag = document.createElement('link');
			tag.setAttribute('rel', 'stylesheet');
			tag.setAttribute('type', 'text/css');
			tag.setAttribute('href', link);
			head.appendChild(tag);
		}
	}
}
