/**
 * Gets the scrollbar width.
 */
const getScrollbarWidth = () => {
	//create the temp element
	let el = document.createElement('div');

	//style the temp element
	el.style.width = '50px';
	el.style.height = '50px';
	el.style.overflow = 'scroll';
	el.style.position = 'absolute';
	el.style.left = '-100px';

	//append the element
	document.body.appendChild(el);

	//get the width
	let scrollbarWidth = el.offsetWidth - el.clientWidth;

	//remove the element
	document.body.removeChild(el);

	//return the width
	return scrollbarWidth;
};

export default {
	init() {
		let scrollbarWidth = getScrollbarWidth();
		let styleTag = document.createElement('style');
		styleTag.type = 'text/css';
		styleTag.innerHTML = 'body { --scrollbar-width: '+scrollbarWidth+'px }';
		document.getElementsByTagName('head')[0].appendChild(styleTag);
	}
};