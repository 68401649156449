const basicScroll = require('basicScroll');
let initAttribute = 'data-parallax-ready';
let instances = [];
export default {
	init(selector = '[data-parallax-scene]') {
		let items = document.querySelectorAll(selector);
		if (items.length) {
			items.forEach(item => {
				if (!item.hasAttribute(initAttribute)) {
					item.setAttribute(initAttribute, '1');
					let varName = item.getAttribute('data-parallax-scene') || 'delta';
					let from = item.getAttribute('data-from') || '-1';
					let to = item.getAttribute('data-to') || '1';
					let fromPos = item.getAttribute('data-from-pos') || 'top-top';
					let toPos = item.getAttribute('data-to-pos') || 'bottom-bottom';
					let options = {
						elem: item,
						//<element>-<viewport>
						from: fromPos,
						to: toPos,
						props: {
							['--'+varName]: {
								from,
								to
							}
						},
						direct: true
					};

					let instance = basicScroll.create(options);
					instance.start();
					instances.push(instance);
				}
			});
		}
	},
	destroy() {
		if (instances.length) {
			instances.forEach(instance => {
				if (instance) {
					instance.destroy();
				}
			});
		}
		instances = [];
	}
}
