let isIE11 = !!(navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv[ :]11/));
if (isIE11) {
	HTMLElement.prototype.getBoundingClientRect = (function () {
		let oldGetBoundingClientRect = HTMLElement.prototype.getBoundingClientRect;
		return function() {
			try {
				return oldGetBoundingClientRect.apply(this, arguments);
			} catch (e) {
				return {
					left: '',
					right: '',
					top: '',
					bottom: ''
				};
			}
		};
	})();
}
