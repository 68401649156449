import debounce from 'debounce';
import ListenerManager from './listenerManager';

let listenerBoss = new ListenerManager();
let initAttribute = 'data-video-ready';

export default {
	init(selector = '[data-video-cover-holder]') {
		let holderEls = document.querySelectorAll(selector);
		holderEls.forEach(holderEl => {
			if (!holderEl.hasAttribute(initAttribute)) {

				//add the attribute
				holderEl.setAttribute(initAttribute, 'y');

				let videoEl = holderEl.querySelector('video, iframe');
				let videoRatio = holderEl.getAttribute('data-width')*1 / holderEl.getAttribute('data-height');

				videoEl.style.height = 'auto';
				videoEl.style.minHeight = 'auto';

				const resizeVideo = () => {
					if (videoEl) {
						const box = holderEl.getBoundingClientRect()
						const actualWidth = box.width
						const actualHeight = box.height
						const actualRatio = actualWidth / actualHeight

						if (actualRatio < videoRatio) {
							videoEl.style.width =
								Math.ceil(actualHeight * videoRatio) + 'px'
							videoEl.style.height = Math.ceil(actualHeight) + 'px'
						} else {
							videoEl.style.width = Math.ceil(actualWidth) + 'px'
							videoEl.style.height =
								Math.ceil(actualWidth * (1 / videoRatio)) + 'px'
						}
					}
				};

				//watch
				['DOMContentLoaded', 'load', 'resize'].forEach(function(eventName) {
					listenerBoss.register(window, eventName, debounce(resizeVideo, 10, false));
				});
				resizeVideo();
			}
		});
	},
	destroy() {
		listenerBoss.removeAll();
	}
}
