/**
 * Manages listeners that they can be easily destroyed later.
 *
 * @copyright Aaron Waldon <aaron@causingeffect.com> 2019
 */
export default class ListenerManager {
	/**
	 * The tracked listeners.
	 * @type {Array}
	 * @private
	 */
	_tracked = [];

	/**
	 * Registers and tracks an event listener.
	 *
	 * @param target
	 * @param type
	 * @param listener
	 */
	register(target, type, listener) {
		target.addEventListener(type, listener);
		this.track(target, type, listener);
	}

	/**
	 * Tracks an event listener
	 * @param target
	 * @param type
	 * @param listener
	 */
	track(target, type, listener) {
		this._tracked.push({target, type, listener});
	}

	/**
	 * Registers and tracks an event listener.
	 *
	 * @param target
	 * @param type
	 * @param listener
	 */
	remove(target, type, listener) {
		target.removeEventListener(type, listener);
		this._tracked.forEach((tracked, trackedIndex) => {
			if (tracked === {target, type, listener}) {
				this._tracked.splice(trackedIndex, i);
			}
		});
	}

	/**
	 * Remove all items.
	 */
	removeAll() {
		this._tracked.forEach(item => {
			item.target.removeEventListener(item.type, item.listener);
		});
		this._tracked = [];
	}
}
