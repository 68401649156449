import simpleStorage from 'simplestorage.js';

//set up the delay
let defaultDelay = 4000;

//if debug, the lightbox will always be shown
let isDebug = false;

//holds the last active element, so it can be restored on close
let previouslyActiveElement = false;

export default class Popover {
	_isOpen = false;
	_onOpenCallback = function() {};
	_onCloseCallback = function() {};

	constructor({
		            onOpen = function(){},
		            onClose = function(){}
	            } = {}) {
		this._onOpenCallback = onOpen;
		this._onCloseCallback = onClose;
	}

	init({
		selector = '[data-popover]',
		closeSelector = '[data-popover-close]'
	     } = {}) {
		let popovers = document.querySelectorAll(selector);
		if (popovers && popovers.length) {
			popovers.forEach(popover => {
				let popoverName = popover.getAttribute('data-popover');
				let popoverDelay = popover.getAttribute('data-popover-delay') || defaultDelay;
				if (popoverName) {
					if (!isDebug && simpleStorage.hasKey(popoverName)) {
						//remove the popover
						popover.parentNode.removeChild(popover);
					} else {
						//make inert
						popover.inert = true;

						setTimeout(()=>{
							this._open(popover);
						}, popoverDelay);

						//close button
						let closeButtons = popover.querySelectorAll(closeSelector);
						if (closeButtons && closeButtons.length) {
							//add close click events
							closeButtons.forEach(closeButton => {
								//add click listener
								closeButton.addEventListener('click', e => {
									e.preventDefault();

									this._close(popover, popoverName);
								});
							});
						}

						//escape key
						window.addEventListener('keydown', e => {
							if (this._isOpen) {
								if(e.key==='Escape'||e.key==='Esc'||e.keyCode===27){
									this._close(popover, popoverName);
								}
							}
						});
					}
				}
			})
		}
	}

	_open(popover) {
		this._isOpen = true;

		//make operative
		popover.inert = false;

		//show the popover
		popover.classList.remove('hidden');

		//set the active item
		previouslyActiveElement = document.activeElement;

		//open callback
		if (this._onOpenCallback) {
			this._onOpenCallback();
		}
	}

	_close(popover, popoverName) {
		this._isOpen = false;

		//set storage key
		simpleStorage.set(popoverName, 'clicked', {TTL: 30*24*60*60*1000});

		//remove the popover
		popover.parentNode.removeChild(popover);

		//close callback
		if (this._onCloseCallback) {
			this._onCloseCallback();
		}

		//attempt to restore focus
		if (previouslyActiveElement) {
			previouslyActiveElement.focus();
		}
	}
}
