

export default class MenuToggles {
	_initAttribute = 'data-toggle-ready';
	_selector;
	_openClass;
	onOpenCallback = function() {};
	onCloseCallback = function() {};
	_isOpen;

	constructor({
		            onOpen = toggle=>{
			            toggle.classList.add(this._openClass);
		            },
		            onClose = toggle=>{
			            toggle.classList.remove(this._openClass);
		            },
		            selector = '[data-toggle]',
		            openedClass = 'toggle--open'
	            } = {}) {
		this.onOpenCallback = onOpen;
		this.onCloseCallback = onClose;
		this._selector = selector;
		this._openClass = openedClass;
	}

	init() {
		//set up the gallery toggles
		let toggles = document.querySelectorAll(this._selector);
		toggles.forEach(toggle => {
			if (!toggle.hasAttribute(this._initAttribute)) {
				//add the init attribute
				toggle.setAttribute(this._initAttribute, 'y');

				toggle.addEventListener('click', ev => {
					ev.preventDefault();

					if (toggle.classList.contains(this._openClass)) {
						if (this.onCloseCallback) {
							this.onCloseCallback(toggle);
						}
					} else {
						if (this.onOpenCallback) {
							this.onOpenCallback(toggle);
						}
					}

					return false;
				});
			}
		});
	}
}
