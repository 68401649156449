/**
 * Polyfill to make sure calendar data uri's can download from Internet Explorer.
 */

let initAttribute = 'data-link-ready';
let isIe = (window.Blob && window.navigator.msSaveOrOpenBlob);
export default {
	init(selector = 'a[data-ics-link]') {
		if (isIe) {
			let links = document.querySelectorAll(selector);
			if (links.length) {
				links.forEach(link => {
					if (!link.hasAttribute(initAttribute)) {
						link.setAttribute(initAttribute, '1');
						link.addEventListener('click', e => {
							e.preventDefault();
							let fileData = [link.getAttribute('href').split("%0A").join("\n").replace('data:text/calendar;charset=utf8,\n', '')];
							window.navigator.msSaveOrOpenBlob(new Blob(fileData), 'event.ics');
						});
					}
				});
			}
		}
	}
}
