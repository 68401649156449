import 'wa-mediabox';
import getVideoId from 'get-video-id'

let initAttribute = 'data-lightbox-ready';

//remember the last opener to try and return focus when closing
let lastOpener = false;

export default class MediaLightbox {
	onOpenCallback = function() {};
	onCloseCallback = function() {};

	constructor({
		            onOpen = function(){},
		            onClose = function(){}
	            } = {}) {
		this.onOpenCallback = onOpen;
		this.onCloseCallback = onClose;
	}

	init() {
		let that = this;

		//clear the galleries
		WAMediaBox.galleries = {};

		//selectors
		let selectors = "a[href*='youtu.be' i], a[href*='youtube.com' i], a[href*='youtube-nocookie.com' i], a[href*='vimeo.com' i], a[href*='.gif' i], a[href*='.jpg' i], a[href*='.png' i]";
		let videoTestStrings = ['youtu.be', 'youtube.com', 'youtube-nocookie.com', 'vimeo.com'];

		//assign items to galleries
		let galleries = document.querySelectorAll('[data-gallery]');
		if (galleries.length) {
			galleries.forEach(gallery => {
				//gallery name
				let galleryName = gallery.getAttribute('data-gallery') || '_';

				//get all the videos in this gallery
				let items = gallery.querySelectorAll(selectors);
				items.forEach(item => {
					item.setAttribute('data-gallery-name', galleryName);
				});
			});
		}

		//set up the gallery items
		let items = document.querySelectorAll(selectors);
		items.forEach(item => {
			if (!item.classList.contains('no-lightbox') && !item.hasAttribute(initAttribute)) {
				//add the init attribute
				item.setAttribute(initAttribute, 'y');

				//get the basics
				let galleryName = item.getAttribute('data-gallery-name') || '_';
				let source = item.getAttribute('href');
				let title = item.getAttribute('title') || '';
				let index;

				//determine if this is a video
				let isVideo = false;
				videoTestStrings.forEach(videoTestString => {
					if (source.indexOf(videoTestString) > -1) {
						isVideo = true;
					}
				});

				if (!isVideo) {
					//if this image has other items on it, let's add them in here
					let galleryImages = item.getAttribute('data-gallery-images') || false;
					if (galleryImages) {
						let images = JSON.parse(galleryImages);
						if (images && images.length) {
							images.forEach((imageData, i) => {
								if (i === 0) {
									index = WAMediaBox.addImage(galleryName, imageData.url, imageData.title);
								} else {
									WAMediaBox.addImage(galleryName, imageData.url, imageData.title);
								}
							});
						}
					} else {
						index = WAMediaBox.addImage(galleryName, source, title);
					}
				} else { //video
					let width = ( item.hasAttribute("data-width") ? parseInt(item.getAttribute("data-width")) : null );
					let height = ( item.hasAttribute("data-height") ? parseInt(item.getAttribute("data-height")) : null );

					let {id, service} = getVideoId(source);

					if (id) {
						if (service === 'youtube') {
							source = 'https://www.youtube-nocookie.com/embed/'+id+'?feature=oembed&rel=0showinfo=0&modestbranding=1&disablekb=1';
						} else if (service === 'vimeo') {
							source = 'https://player.vimeo.com/video/'+id;
						}
					}

					index = WAMediaBox.addIframe(galleryName, source, title, width, height);
				}

				item.addEventListener('click', ev => {
					ev.preventDefault();
					ev.stopPropagation();
					ev.cancelBubble = true;

					lastOpener = item;

					WAMediaBox.openGallery(galleryName, index);

					if (this.onOpenCallback) {
						this.onOpenCallback();
					}

					//try to set focus on the iframe video
					window.setTimeout(()=>{
						let video = document.querySelector('.wa-mediabox-frame iframe');
						if (video) {
							video.addEventListener('load', () => {
								if (video) {
									video.contentWindow.focus();
								}
							});
						}
					}, 200);

					return false;
				});
			}
		});

		if (WAMediaBox.hasOwnProperty('galleries') && Object.keys(WAMediaBox.galleries).length) {
			for (let galleryName of Object.keys(WAMediaBox.galleries)) {
				let gallery = WAMediaBox.galleries[galleryName];

				//overwrite the gallery close method so that we can have a callback
				let originalCloseMethod = gallery.close;
				gallery.close = function() {
					//call the original method
					originalCloseMethod.call(this);

					//call our callback
					if (that.onCloseCallback) {
						that.onCloseCallback();
					}

					//return focus
					if (lastOpener) {
						lastOpener.focus();
					}
				};
			}
		}
	}

	close() {
		//forget the opener, we're probably changing pages
		lastOpener = false;

		if (WAMediaBox.hasOwnProperty('galleries') && Object.keys(WAMediaBox.galleries).length) {
			for (let galleryName of Object.keys(WAMediaBox.galleries)) {
				let gallery = WAMediaBox.galleries[galleryName];

				gallery.close();

				if (this.onCloseCallback) {
					this.onCloseCallback();
				}
			}
		}
	}
}
